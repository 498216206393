import { NavLink } from "react-router-dom";

import { Box } from "@mui/material";

import "./menuLetters.css";

interface Props {
  sortDirection: number;
  sortField: string;
  sortFieldUpdate: Function;
  sortFieldNext: string;
  sortDirectionUpdate: Function;
}

const MenuLetters = ({ sortDirection, sortField, sortFieldUpdate, sortFieldNext, sortDirectionUpdate }: Props) => {
  let aToZ: string[] = [];

  let i: number;

  for (i = 0; i < 26; i++) {
    aToZ.push(String.fromCharCode(65 + i));
  }

  return (
    <Box
      className="menuLetters"
      role="menubar"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box role="none" className="menuLetterButton">
        <NavLink
          role="menuitem"
          className="navlink"
          to={`/CardList/0/${sortFieldNext}`}
          onClick={() => {
            sortFieldUpdate();
          }}
        >
          ...
        </NavLink>
      </Box>
      {aToZ.map((c: string) => (
        <Box role="none" key={`${c}`} className="menuLetterButton">
          <NavLink
            role="menuitem"
            className="navlink"
            to={`/CardList/${c}/${sortField}`}
            onClick={() => {
              sortFieldUpdate();
            }}
          >
            {c}
          </NavLink>
        </Box>
      ))}
      <Box role="none" className="menuLetterButton2">
        <NavLink
          role="menuitem"
          className="navlink2"
          to={`/CardList/1/${sortField}`}
          onClick={() => {
            // console.log("MenuLetters sortDirection", sortDirection);
            sortDirectionUpdate(-sortDirection);
          }}
        >
          {(sortDirection === 1) ? "⬇︎" : "⬆︎"}

        </NavLink>
      </Box>
    </Box>
  );
};

export default MenuLetters;
