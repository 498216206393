import { NavLink, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";

import "./cardList.css";

import BookCard from "../../models/bookCard";

import CardItem from "../../components/cardItem/cardItem";

interface Props {
  sortDirection: number;
  setBookCard: Function;
  cardOrigin: BookCard[];
  action: string;
  setAction: Function;
}

const CardList = ({ sortDirection, setBookCard, cardOrigin, action, setAction }: Props) => {

  //  console.log("sortDirection", sortDirection);

  // console.log("action", action);

  const { t } = useTranslation();

  const { filter, sortField } = useParams();

  const [cardList, setCardList] = useState<BookCard[]>([]);

  let newId: number;

  newId = Math.max(...cardOrigin.map((dataCard) => dataCard.id)) + 1;

  if (action === "A") {
    setAction("L");
  } else if (action === "D") {
    setAction("L");
  } else if (action === "C") {
    setAction("L");
  } else if (action === "M") {
    setAction("L");
  } if (action === "V") {
    setAction("L");
  }

  useEffect(() => {

    //  console.log(cardOrigin, filter, sortField, cardList, sortDirection);

    let filteredCards: BookCard[];

    if (!filter) {

      filteredCards = [...cardOrigin];

    } else if (filter === "1") {

      filteredCards = [...cardList];

    } else if (filter.length === 1 && filter.match(/[a-zA-Z]/)) {

      filteredCards = [
        ...cardOrigin.filter((bookCard: BookCard) =>
          bookCard.lastname.startsWith(filter ? filter : "")
        ),
      ];

    } else {

      filteredCards = cardOrigin;

    }
    /*
        console.log("filteredCards", filteredCards);
        console.log("sortDirection", sortDirection);
    */
    let sortedCards: BookCard[] = filteredCards.sort(
      (a: BookCard, b: BookCard) => {
        let valA: number | string = a.fullname;
        let valB: number | string = b.fullname;

        if (sortField === "id") {
          valA = a.id;
          valB = b.id;
        } else if (sortField === "firstname") {
          valA = a.firstname + " " + a.lastname;
          valB = b.firstname + " " + b.lastname;
        }

        return valA > valB ? sortDirection : -sortDirection;
      }
    );

    setCardList(sortedCards);

    setBookCard({
      id: newId,
      firstname: "",
      lastname: "",
      fullname: "",
      birthday: "",
      gender: "",
      phone: "",
      address_street_1: "",
      address_street_2: "",
      address_street_3: "",
      hamlet: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      picture: "",
    });

  }, [cardOrigin, filter, sortField, sortDirection]);


  return (
    <>
      <Box className="mainBox2">
        <Box className="mainBox3">
          <Box
            className="itemsList"
            display="flex"
            flexDirection="column"
            id="list"
          >
            {cardList?.map((bookCard: BookCard) => (
              <Box key={`${bookCard.fullname}`}>
                <CardItem bookCard={bookCard} />
              </Box>
            ))}
          </Box>
        </Box>
        <Box className="intervalleH2" />
        <Box className="buttonBox">
          <Button className="buttonBox2" variant="contained" sx={{ backgroundColor: "#3465a4", color: "#ffffff" }} >
            <NavLink className="navlink" to={`/CardEdit/${newId}`}>
              {/*<NavLink className="navlink" to={`/CardEdit/0`}>*/}
              {t("common.createCard")}
            </NavLink>
          </Button>
        </Box>
      </Box >
    </>
  );
};

export default CardList;
