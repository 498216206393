import { NavLink, useParams } from "react-router-dom";
import { useState } from "react";

import { Box, Button, CardMedia, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./cardView.css";

import BookCard from "../../models/bookCard";
import CardValid from "../../components/cardValid/cardValid";
import dayjs from "dayjs";

interface Props {
  cardOrigin: BookCard[];
  bookCard: BookCard | undefined;
  //  setBookCard: Function;
  action: string;
  setAction: Function;
  handleCardChange: Function;
  handleCardSelect: Function;
}

const CardView = ({ cardOrigin, bookCard, /* setBookCard, */ action, setAction, handleCardChange, handleCardSelect }: Props) => {

  const { t } = useTranslation();

  const { filter } = useParams();

  // console.log(filter);
  // console.log("action", action);

  if (action === "L") {
    setAction("V");
  }

  if (cardOrigin.length !== 0) {
    console.log("cardOrigin", cardOrigin);
  } else {
    console.log("filter", filter);
  }

  let bookCardSelect: BookCard = cardOrigin.filter(
    (card) => card.id.toString() === filter
  )[0];

  handleCardSelect(bookCardSelect);

  //  console.log("bookCard.id", bookCardSelect.id);

  //  console.log("bookCard.phone", bookCardSelect.phone.replace(/(.{2})/g, "$1 ").trim());

  const [openValidDelete, setOpenValidDelete] = useState<boolean>(false);

  const handleOpenValidDelete = () => {
    setOpenValidDelete(true);
  };

  const handleCloseValidDelete = () => {
    setOpenValidDelete(false);
  };

  return (
    <>
      <Box className="mainBox2">
        <Box className="mainBox3">
          <Box className="itemCard" display="flex" flexDirection="column">
            <Box className="firstCard">
              <Box className="image">
                <CardMedia
                  component="img"
                  image={
                    bookCardSelect.picture === ""
                      ? bookCardSelect.gender === "F"
                        ? process.env.PUBLIC_URL + "/assets/images/femme.png"
                        : process.env.PUBLIC_URL + "/assets/images/homme.png"
                      : process.env.PUBLIC_URL + bookCardSelect.picture
                  }
                  alt={bookCardSelect.fullname}
                ></CardMedia>
              </Box>
              <Box className="firstCard_D">
                <Box className="cardLineEdit">
                  <Typography className="intitule">
                    {t("card.firstname") + " : "}
                  </Typography>
                  <Box className="edit2">
                    <Typography className="valeur2" width="8em">
                      {bookCardSelect.firstname}
                    </Typography>
                  </Box>
                </Box>
                <Box className="cardLineEdit">
                  <Typography className="intitule">
                    {t("card.lastname") + " : "}
                  </Typography>
                  <Box className="edit2">
                    <Typography className="valeur" width="8em">
                      {bookCardSelect.lastname}
                    </Typography>
                  </Box>
                </Box>
                <Box className="cardLineEdit">
                  <Typography className="intitule">
                    {t("card.phone") + " : "}
                  </Typography>
                  <Box className="edit2">
                    <Typography className="valeur" width="8em">
                      {bookCardSelect.phone.replace(/(.{2})/g, "$1 ").trim()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="secondCard">
              <Box className="thirdCard">
                <Box className="cardLineEdit">
                  <Typography className="intitule" width="12em">
                    {t("card.birthday") + " : "}
                  </Typography>
                  <Box className="edit" width="10em">
                    <Typography className="valeur" width="8em">
                      {bookCardSelect.birthday.length === 0
                        ? "../../...."
                        : dayjs(bookCardSelect.birthday).format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="cardLineEdit">
                  <Typography className="intitule">
                    {t("card.gender") + " : "}
                  </Typography>
                  <Box className="edit" width="84em">
                    <Typography className="valeur" width="3em">
                      {bookCardSelect.gender}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="fourthCard">
              <Box className="fifthCard">
                <Box className="cardLineEdit2">
                  <Typography className="intitule">
                    {t("card.address") + " : "}
                  </Typography>
                  <Box className="edit2">
                    <Typography className="valeur" width="18em">
                      {bookCardSelect.address_street_1}
                    </Typography>
                  </Box>
                  <Box className="edit2">
                    <Typography className="valeur" width="18em">
                      {bookCardSelect.address_street_2}
                    </Typography>
                  </Box>
                  <Box className="edit2">
                    <Typography className="valeur" width="18em">
                      {bookCardSelect.address_street_3}
                    </Typography>
                  </Box>
                </Box>
                <Box className="sixthCard">
                  <Box className="edit" width="8em">
                    <Typography className="valeur2">
                      {bookCardSelect.hamlet}
                    </Typography>
                  </Box>
                  <Box className="edit" width="8.5em">
                    <Typography className="valeur2">{bookCardSelect.city}</Typography>
                  </Box>
                  <Box className="edit" width="5em">
                    <Typography className="valeur2">{bookCardSelect.zip}</Typography>
                  </Box>
                </Box>
                <Box className="sixthCard">
                  <Box className="edit" width="11em">
                    <Typography className="valeur2">
                      {bookCardSelect.state}
                    </Typography>
                  </Box>
                  <Box className="edit" width="11em">
                    <Typography className="valeur2">
                      {bookCardSelect.country}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="intervalleH2" />
        <Box className="buttonBox">
          <Button
            className="buttonBox2"
            variant="contained"
            sx={{ backgroundColor: "#3465a4", color: "#ffffff" }}
          >
            <NavLink
              className="navlink"
              to=""
              onClick={() => handleOpenValidDelete()}
            >
              {t("common.deleteCard")}
            </NavLink>
          </Button>
          <Button
            className="buttonBox2"
            variant="contained"
            sx={{ backgroundColor: "#3465a4", color: "#ffffff" }}
          >
            <NavLink
              className="navlink"
              to={"/CardEdit/" + bookCardSelect.id.toString()}
            >
              {t("common.modifyCard")}
            </NavLink>
          </Button>
        </Box>
      </Box>

      <Modal
        open={openValidDelete}
        onClose={handleCloseValidDelete}
        aria-labelledby={t("bookCard." + bookCardSelect.id)}
        aria-describedby={t("bookCard." + bookCardSelect.id)}
      >
        <div>
          <CardValid
            card={bookCard}
            action={"D"}
            setAction={setAction}
            handleCloseValid={handleCloseValidDelete}
            handleCardChange={handleCardChange}
          />
        </div>
      </Modal>
    </>
  );
};

export default CardView;
