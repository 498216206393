import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  CardMedia,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
//import moment from 'moment';

import DatePicker, { getDefaultLocale } from "react-datepicker";
import { fr } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";

import { useTranslation } from "react-i18next";

import { useFormik, /* useFormikContext */ } from "formik";
import * as yup from "yup";

import "./cardEdit.css";

import BookCard from "../../models/bookCard";
import CardValid from "../../components/cardValid/cardValid";

registerLocale('fr', fr)

interface Props {
  bookCard: BookCard | undefined;
  setBookCard: Function;
  cardOrigin: BookCard[];
  handleCardChange: Function;
  datePickerLanguage: string;
  action: string;
  setAction: Function;
}

const CardEdit = ({
  bookCard,
  setBookCard,
  cardOrigin,
  handleCardChange,
  datePickerLanguage,
  action,
  setAction
}: Props) => {

  // console.log("action", action);

  //  console.log("CardEdit 35", bookCard);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { filter } = useParams();

  if (action == "L") {
    setAction("C");
  } else if (action == "V") {
    setAction("M");
  }

  if (action === "M" || action === "C") {

    if (filter === "0") {
      navigate("/");
    }
  }

  if (filter === "0") {
    action = "C";
  }

  let newId: number =
    Math.max(...cardOrigin.map((dataCard) => dataCard.id)) + 1;

  let bookCardMod: BookCard = {
    id: newId,
    firstname: "",
    lastname: "",
    fullname: "",
    birthday: "",
    gender: "",
    phone: "",
    address_street_1: "",
    address_street_2: "",
    address_street_3: "",
    hamlet: "",
    city: "",
    zip: "",
    state: "",
    country: "",
    picture: "",
  };

  if (bookCard === undefined || filter === "0") {
    let newId: number =
      Math.max(...cardOrigin.map((dataCard) => dataCard.id)) + 1;

    bookCardMod.id = newId;
  } else {
    bookCardMod = bookCard;
  }

  setBookCard(bookCardMod);

  // console.log("cardEdit filter", filter);
  // console.log("cardEdit bookCard", bookCard);

  // console.log("cardEdit 88 bookCardMod", bookCardMod);

  const schema = yup.object().shape({
    firstname: yup
      .string()
      .required(
        t("error.required", { field: t("card.firstname") }).toLowerCase()
      )
      .min(3),
    lastname: yup
      .string()
      .required(
        t("error.required", { field: t("card.lastname") }).toLowerCase()
      )
      .min(3),
    phone: yup
      .string()
      .required(t("error.required", { field: t("card.phone") }).toLowerCase())
      .min(10),
    birthday: yup
      .date()
      .typeError(t("error.validDate"))
      .min(new Date("1919-11-19"), t("error.olderDate"))
      .max(new Date(), t("error.futureDate")),
    gender: yup
      .string()
      .transform(
        (value) =>
        (value =
          value.toUpperCase() === "W"
            ? "F"
            : value.toUpperCase() === "M"
              ? "H"
              : value.toUpperCase())
      )
      .matches(/[fFhHmMwW]{1}/, t("error.onlyOne"))
      .max(1),
  });

  //  console.log("169 bookCard", bookCardMod);

  const [picture, setPicture] = useState<string>();

  const formik = useFormik({
    initialValues: {
      id: bookCardMod.id,
      firstname: bookCardMod.firstname,
      lastname: bookCardMod.lastname,
      fullname: bookCardMod.fullname,
      birthday: bookCardMod.birthday,
      gender: bookCardMod.gender,
      phone: bookCardMod.phone,
      address_street_1: bookCardMod.address_street_1,
      address_street_2: bookCardMod.address_street_2,
      address_street_3: bookCardMod.address_street_3,
      hamlet: bookCardMod.hamlet,
      city: bookCardMod.city,
      zip: bookCardMod.zip,
      state: bookCardMod.state,
      country: bookCardMod.country,
      picture: bookCardMod.picture,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      let newCard: BookCard = {
        id: values.id,
        firstname: values.firstname,
        lastname: values.lastname,
        fullname: values.fullname,
        birthday: values.birthday,
        gender: values.gender,
        phone: values.phone,
        address_street_1: values.address_street_1,
        address_street_2: values.address_street_2,
        address_street_3: values.address_street_3,
        hamlet: values.hamlet,
        city: values.city,
        zip: values.zip,
        state: values.state,
        country: values.country,
        picture: values.picture,
      };
      // alert(JSON.stringify(values.birthday, null, 2));

      newCard.fullname = newCard.lastname + " " + newCard.firstname;

      // console.log("cardEdit 156 newCard", newCard);

      // console.log(newCard.birthday);
      // console.log(values.birthday);
      // console.log(JSON.stringify(values.birthday, null, 2));
      // console.log(JSON.stringify(values.birthday, null, 2).replace(/"/g, ""));

      bookCardMod = newCard;

      setBookCard(newCard);

      handleOpenValidRecord();

      // console.log("cardEdit 157 bookCardMod", bookCardMod);
      // console.log("cardEdit 158 bookCard", bookCard);

      /*
      handleCardChange(bookCard);
      /*       handleCloseEdit();   */
    },
  });

  // console.log("formik.values", formik.values);

  const [openValidCancel, setOpenValidCancel] = useState<boolean>(false);
  const [openValidRecord, setOpenValidRecord] = useState<boolean>(false);
  // const { setFieldValue } = useFormikContext();
  const handleOpenValidCancel = () => {
    setOpenValidCancel(true);
  };
  const handleCloseValidCancel = () => {
    setOpenValidCancel(false);
  };
  const handleOpenValidRecord = () => {
    setOpenValidRecord(true);
  };
  const handleCloseValidRecord = () => {
    setOpenValidRecord(false);
  };
  /*
    const [picture, setPicture] = useState();
  
    function pictureChange(e) {
      console.log(e.target.files);
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  */

  /*
    const days = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim']
    const months = ['janvier', 'février', 'mars', 'avril', 'may', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
  
    const locale = {
      localize: {
        day: (n: number) => days[n],
        month: (n: number) => months[n]
      },
      formatLong: {
        date: () => 'dd/mm/yyyy'
      }
    }
  */
  //  setDefaultLocale("fr");

  // console.log("getDefaultLocale", getDefaultLocale());

  const [startDate, setStartDate] = useState(formik.values.birthday === "" ? null : new Date(formik.values.birthday));

  useEffect(() => {
    //    console.log("useEffect picture", bookCardMod.picture);
    //    console.log("useEffect gender", formik.values.gender);
    //    console.log("useEffect birthday", startDate, formik.values.birthday);

    setPicture(
      bookCardMod.picture === ""
        ? ["f", "F", "w", "W"].includes(formik.values.gender)
          ? //        ? formik.values.gender === "W" || formik.values.gender === "F"
          process.env.PUBLIC_URL + "/assets/images/femme.png"
          : ["h", "H", "m", "M"].includes(formik.values.gender)
            ? process.env.PUBLIC_URL + "/assets/images/homme.png"
            : ""
        : process.env.PUBLIC_URL + bookCardMod.picture
    );

    //    console.log("useEffect picture", bookCardMod.picture);
    //    console.log("useEffect startDate", startDate);

    formik.values.birthday = (startDate === null) ? "" : startDate.toISOString();
    //    console.log("useEffect birthday", startDate, formik.values.birthday);

  }, [formik.values.gender, bookCardMod.picture, startDate]);


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box className="mainBox2">
          <Box className="mainBox3">
            <Box className="itemCard" display="flex" flexDirection="column">
              <Box className="firstCard">
                <Box className="image">
                  <CardMedia
                    component="img"
                    image={picture}
                    alt={bookCardMod.fullname}
                  ></CardMedia>
                </Box>
                <Box className="firstCard_D">
                  <Box className="cardLine">
                    <Typography className="descriptif">
                      {t("card.firstname") + " : "}
                    </Typography>
                    <Box className="edit" display={"flex"}>
                      <InputLabel className="label">
                        {t("card.firstname")}
                      </InputLabel>
                      <TextField
                        className="edit"
                        placeholder={t("card.firstname")}
                        type="string"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="firstname"
                        value={formik.values.firstname}
                        error={
                          formik.touched.firstname &&
                          Boolean(formik.errors.firstname)
                        }
                        helperText={
                          formik.touched.firstname && formik.errors.firstname
                        }
                      />
                    </Box>
                  </Box>
                  <Box className="cardLine">
                    <Typography className="descriptif">
                      {t("card.lastname") + " : "}
                    </Typography>
                    <Box className="edit" display={"flex"}>
                      <InputLabel className="label">
                        {t("card.lastname")}
                      </InputLabel>
                      <TextField
                        className="edit"
                        placeholder={t("card.lastname")}
                        type="string"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="lastname"
                        value={formik.values.lastname}
                        error={
                          formik.touched.lastname &&
                          Boolean(formik.errors.lastname)
                        }
                        helperText={
                          formik.touched.lastname && formik.errors.lastname
                        }
                      />
                    </Box>
                  </Box>
                  <Box className="cardLine">
                    <Typography className="descriptif">
                      {t("card.phone") + " : "}
                    </Typography>
                    <Box className="edit" display={"flex"}>
                      <InputLabel className="label">
                        {t("card.phone")}
                      </InputLabel>
                      <TextField
                        className="edit"
                        placeholder={t("card.phone")}
                        type="string"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="phone"
                        value={formik.values.phone}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                        sx={{ height: 1.1 }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="secondCard">
                <Box className="thirdCard">
                  <Box className="cardLine2">
                    <Typography className="descriptif">
                      {t("card.birthday") + " : "}
                    </Typography>
                    <Box className="edit3" display={"flex"}>
                      <InputLabel className="label">
                        {t("card.birthday")}
                      </InputLabel>
                      <DatePicker
                        locale={getDefaultLocale()}
                        className="edit4"
                        selected={startDate}
                        //selected={new Date(formik.values.birthday)}
                        onChange={(date: Date) => setStartDate(date)}
                        //  onChange={(date: Date) => { console.log(date); setStartDate(date) }}
                        //  className="edit"
                        name="birthday"
                        // format="dd/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date("1919-11-19T00:00:00.000Z")}
                        maxDate={new Date()}
                      //  defaultValue={dayjs("2022-02-22")}
                      //  value={moment(formik.values.birthday, "DD-MM-YYYY").toString()}
                      //  onChange={handleDateChange}
                      //  onChange={(val: any) => {
                      //    setFieldValue("birthday", val);
                      //  }}
                      //  onChange={formik.handleChange}
                      //                          onError={(newError) => setError(newError)}
                      // slotProps={{
                      //   textField: {
                      //     helperText:
                      //       formik.touched.birthday && formik.errors.birthday,
                      //     error:
                      //       formik.touched.birthday &&
                      //       Boolean(formik.errors.birthday),
                      //   },
                      // }}
                      //                          minDate={startOfQ12022}
                      //                          maxDate={endOfQ12022}
                      >
                        <fieldset aria-hidden="true" className="MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline"><legend className="css-ihdtdm"><span className="notranslate">​</span></legend></fieldset>
                      </DatePicker>
                    </Box>
                  </Box>
                  <Box className="cardLine2">
                    <Typography className="descriptif">
                      {t("card.gender") + " : "}
                    </Typography>
                    <Box className="edit" width="3em">
                      <InputLabel className="label">
                        {t("card.gender")}
                      </InputLabel>
                      <TextField
                        className="edit"
                        placeholder={t("card.gender")}
                        type="string"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="gender"
                        value={formik.values.gender}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                        helperText={
                          formik.touched.gender && formik.errors.gender
                        }
                        sx={{ height: 1.1 }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="fourthCard">
                <Box className="fifthCard">
                  <Box className="cardLine4">
                    <Typography className="descriptif">
                      {t("card.address") + " : "}
                    </Typography>
                    <Box className="edit2">
                      <InputLabel className="label">
                        {t("card.address_street_1")}
                      </InputLabel>
                      <TextField
                        className="edit"
                        placeholder={t("card.address_street_1")}
                        type="string"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="address_street_1"
                        value={formik.values.address_street_1}
                        error={
                          formik.touched.address_street_1 &&
                          Boolean(formik.errors.address_street_1)
                        }
                        helperText={
                          formik.touched.address_street_1 &&
                          formik.errors.address_street_1
                        }
                      />
                    </Box>
                    <Box className="edit2">
                      <InputLabel className="label">
                        {t("card.address_street_2")}
                      </InputLabel>
                      <TextField
                        className="edit"
                        placeholder={t("card.address_street_2")}
                        type="string"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="address_street_2"
                        value={formik.values.address_street_2}
                        error={
                          formik.touched.address_street_2 &&
                          Boolean(formik.errors.address_street_2)
                        }
                        helperText={
                          formik.touched.address_street_2 &&
                          formik.errors.address_street_2
                        }
                      />
                    </Box>
                    <Box className="edit2">
                      <InputLabel className="label">
                        {t("card.address_street_3")}
                      </InputLabel>
                      <TextField
                        className="edit"
                        placeholder={t("card.address_street_3")}
                        type="string"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="address_street_3"
                        value={formik.values.address_street_3}
                        error={
                          formik.touched.address_street_3 &&
                          Boolean(formik.errors.address_street_3)
                        }
                        helperText={
                          formik.touched.address_street_3 &&
                          formik.errors.address_street_3
                        }
                      />
                    </Box>
                    <Box className="sixthCard">
                      <Box className="edit" width="8em">
                        <InputLabel className="label">
                          {t("card.hamlet")}
                        </InputLabel>
                        <TextField
                          className="edit"
                          placeholder={t("card.hamlet")}
                          type="string"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="hamlet"
                          value={formik.values.hamlet}
                          error={
                            formik.touched.hamlet &&
                            Boolean(formik.errors.hamlet)
                          }
                          helperText={
                            formik.touched.hamlet && formik.errors.hamlet
                          }
                        />
                      </Box>
                      <Box className="edit" width="8.5em">
                        <InputLabel className="label">
                          {t("card.city")}
                        </InputLabel>
                        <TextField
                          className="edit"
                          placeholder={t("card.city")}
                          type="string"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="city"
                          value={formik.values.city}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                          sx={{ height: 1.1 }}
                        />
                      </Box>
                      <Box className="edit" width="5em">
                        <InputLabel className="label">
                          {t("card.zip")}
                        </InputLabel>
                        <TextField
                          className="edit"
                          placeholder={t("card.zip")}
                          type="string"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="zip"
                          value={formik.values.zip}
                          error={
                            formik.touched.zip && Boolean(formik.errors.zip)
                          }
                          helperText={formik.touched.zip && formik.errors.zip}
                        />
                      </Box>
                    </Box>
                    <Box className="sixthCard">
                      <Box className="edit" width="11.5em">
                        <InputLabel className="label">
                          {t("card.state")}
                        </InputLabel>
                        <TextField
                          className="edit"
                          placeholder={t("card.state")}
                          type="string"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="state"
                          value={formik.values.state}
                          error={
                            formik.touched.state && Boolean(formik.errors.state)
                          }
                          helperText={
                            formik.touched.state && formik.errors.state
                          }
                        />
                      </Box>
                      <Box className="edit" width="10.5em">
                        <InputLabel className="label">
                          {t("card.country")}
                        </InputLabel>
                        <TextField
                          className="edit"
                          placeholder={t("card.country")}
                          type="string"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="country"
                          value={formik.values.country}
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country && formik.errors.country
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="intervalleH2" />
          <Box className="buttonBox">
            <Button
              className="buttonBox2"
              variant="contained"
              sx={{ backgroundColor: "#3465a4", color: "#ffffff" }}
            >
              <NavLink
                className="navlink"
                to="" onClick={() => handleOpenValidCancel()}>
                {action === "C"
                  ? t("common.returnToList")
                  : t("common.returnToCard")}
              </NavLink>
            </Button>
            <Button
              className="buttonBox2"
              variant="contained"
              sx={{ backgroundColor: "#3465a4", color: "#ffffff" }}
              type="submit"
            >
              {t("common.recordCard")}
            </Button>
          </Box>
        </Box>
      </form>
      <Modal
        open={openValidCancel}
        onClose={handleCloseValidCancel}
        aria-labelledby={t("bookCard." + bookCardMod.id)}
        aria-describedby={t("bookCard." + bookCardMod.id)}
      >
        <div>
          <CardValid
            card={bookCard}
            action={"A"}
            setAction={setAction}
            handleCloseValid={handleCloseValidCancel}
            handleCardChange={handleCardChange}
          />
        </div>
      </Modal>
      <Modal
        open={openValidRecord}
        onClose={handleCloseValidRecord}
        aria-labelledby={t("bookCard." + bookCardMod.id)}
        aria-describedby={t("bookCard." + bookCardMod.id)}
      >
        <div>
          <CardValid
            card={bookCardMod}
            action={action}
            setAction={setAction}
            handleCloseValid={handleCloseValidRecord}
            handleCardChange={handleCardChange}
          />
        </div>
      </Modal>
    </>
  );
};

export default CardEdit;
