import { NavLink } from 'react-router-dom';

import { Box, Button, Paper, Typography } from '@mui/material'
import { Home } from '@mui/icons-material'

import "./error404.css";

const Error404 = () => {

  return (
    <Box className="mainBox4">
      <Box className="mainBox3">
        <Paper
          elevation={12}
          sx={{
            margin: 0,
            height: '10em',
            width: '15em',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: `10em`,
            }}
          >
            <Typography variant="h4">404 error</Typography>
            <Typography variant="subtitle1">
              {'page_not_found'}
            </Typography>
            <NavLink to="/">
              <Button
                color="secondary"
                aria-label="home"
                style={{ marginTop: 20 }}
              >
                <Home />
              </Button>
            </NavLink>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default Error404
