import BookCard from "../models/bookCard";

export const bookCardData: BookCard[] = [
	{
		id: 1,
		fullname: "Kao Lyn",
		firstname: "Lyn",
		lastname: "Kao",
		birthday: "2003-08-15T00:00:00.000Z",
		gender: "F",
		phone: "0123456789",
		address_street_1: "97 Po Kong Village Rd",
		address_street_2: "",
		address_street_3: "",
		hamlet: "",
		city: "Hong Kong",
		zip: "",
		state: "",
		country: "Hong Kong",
		picture: "/assets/images/vue-color-avatar/2.png"
	},
	{
		id: 2,
		fullname: "Quiraz Mustapha",
		firstname: "Mustapha",
		lastname: "Quiraz",
		birthday: "1976-02-29T00:00:00.000Z",
		gender: "M",
		phone: "0123456789",
		address_street_1: "41 İstanbul Cd.",
		address_street_2: "",
		address_street_3: "",
		hamlet: "",
		city: "Konya",
		zip: "",
		state: "",
		country: "Turkey",
		picture: "/assets/images/vue-color-avatar/30.png"
	},
	{
		id: 3,
		fullname: "Cabas Fra",
		firstname: "Fra",
		lastname: "Cabas",
		birthday: "1944-12-25T00:00:00.000Z",
		gender: "F",
		phone: "0123456789",
		address_street_1: "161 Carrer d'Aragó",
		address_street_2: "",
		address_street_3: "",
		hamlet: "",
		city: "Barcelona",
		zip: "08007",
		state: "",
		country: "Espanya",
		picture: ""
	},
	{
		id: 4,
		fullname: "Goorgoor Lew",
		firstname: "Lew",
		lastname: "Goorgoor",
		birthday: "",
		gender: "M",
		phone: "0123456789",
		address_street_1: "7794 N Jefferson Place Cir",
		address_street_2: "",
		address_street_3: "",
		hamlet: "",
		city: "Baton-Rouge, LA",
		zip: "70809",
		state: "",
		country: "United States",
		picture: "/assets/images/vue-color-avatar/17.png"
	},
	{
		id: 5,
		fullname: "Purprea Lili",
		firstname: "Lili",
		lastname: "Purprea",
		birthday: "2005-01-08T00:00:00.000Z",
		gender: "F",
		phone: "0123456789",
		address_street_1: "19 Beloka Pasealekua",
		address_street_2: "",
		address_street_3: "",
		hamlet: "",
		city: "Donostia",
		zip: "20009",
		state: "Guipuscoa",
		country: "Espainia",
		picture: "/assets/images/vue-color-avatar/3.png"
	},
	{
		id: 6,
		fullname: "Lodge Oak",
		firstname: "Oak",
		lastname: "Lodge",
		birthday: "1968-05-08T00:00:00.000Z",
		gender: "M",
		phone: "0123456789",
		address_street_1: "Giant Springs State Park",
		address_street_2: "4803 Giant Springs Rd",
		address_street_3: "",
		hamlet: "Great Falls, MT",
		city: "Cascade",
		zip: "59405",
		state: "",
		country: "United States",
		picture: ""
	},
	{
		id: 7,
		fullname: "Quivole Ondine",
		firstname: "Ondine",
		lastname: "Quivole",
		birthday: "1994-10-03T00:00:00.000Z",
		gender: "F",
		phone: "0123456789",
		address_street_1: "2 Rue Léo Delibes",
		address_street_2: "",
		address_street_3: "",
		hamlet: "",
		city: "Paris",
		zip: "75015",
		state: "",
		country: "France",
		picture: "/assets/images/vue-color-avatar/22.png"
	},
	{
		id: 8,
		fullname: "Machado Antonio",
		firstname: "Antonio",
		lastname: "Machado",
		birthday: "",
		gender: "M",
		phone: "0123456789",
		address_street_1: "Pje. La Luna",
		address_street_2: "los angeles",
		address_street_3: "",
		hamlet: "Laguna Verde",
		city: "Valparaiso",
		zip: "",
		state: "",
		country: "Chili",
		picture: "/assets/images/vue-color-avatar/21.png"
	},
	{
		id: 9,
		fullname: "Malthus Thomas Robert",
		firstname: "Thomas Robert",
		lastname: "Malthus",
		birthday: "1966-02-13T00:00:00.000Z",
		gender: "M",
		phone: "",
		address_street_1: "4 Pulteney Court",
		address_street_2: "Pulteney Road (South)",
		address_street_3: "",
		hamlet: "",
		city: "Bath",
		zip: "BA2 4HL",
		state: "",
		country: "England",
		picture: "/assets/images/vue-color-avatar/14.png"
	},
	{
		id: 10,
		fullname: "Tyson William",
		firstname: "William",
		lastname: "Tyson",
		birthday: "1956-02-28T00:00:00.000Z",
		gender: "M",
		phone: "0123456789",
		address_street_1: "56 Chem. aux Raisins",
		address_street_2: "",
		address_street_3: "",
		hamlet: "",
		city: "Berck-sur-Denim",
		zip: "",
		state: "",
		country: "France",
		picture: "/assets/images/vue-color-avatar/1.png"
	},
	{
		id: 11,
		fullname: "Ts’ao Ts’ao Tchin",
		firstname: "Tchin",
		lastname: "Ts’ao Ts’ao",
		birthday: "",
		gender: "M",
		phone: "0123456789",
		address_street_1: "37/107",
		address_street_2: "1357 Mao Tse Dong ave",
		address_street_3: "",
		hamlet: "",
		city: "",
		zip: "",
		state: "",
		country: "China",
		picture: "/assets/images/vue-color-avatar/12.png"
	},
	{
		id: 12,
		fullname: "Quiraz Abdulla",
		firstname: "Abdulla",
		lastname: "Quiraz",
		birthday: "1969-12-10T00:00:00.000Z",
		gender: "M",
		phone: "0123456789",
		address_street_1: "41 İstanbul Cd.",
		address_street_2: "",
		address_street_3: "",
		hamlet: "",
		city: "Konya",
		zip: "",
		state: "",
		country: "Turkey",
		picture: "/assets/images/vue-color-avatar/6.png"
	}
];
