import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppBar, Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import { getDefaultLocale } from "react-datepicker";
// import { fr } from "date-fns/locale";

import "./header.css";

interface Props {
  setDatePickerLanguage: Function;
}

const Header = ({ setDatePickerLanguage }: Props) => {
  const { t, i18n } = useTranslation();

  const toggleLanguage = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: string
  ) => {
    i18n.changeLanguage(newLanguage);

    // console.log("newLanguage", newLanguage);

    setDatePickerLanguage(newLanguage);

    // console.log("getDefaultLocale", getDefaultLocale());

    //    registerLocale('fr', fr)
  };

  return (
    <AppBar className="header">
      <Box
        className="appBar"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="0"
        textAlign="center"
      >
        <NavLink to="/">
          <Box display="flex" alignItems="center">
            <Typography variant="h1" className="menuTitre" >
              {t("common.title")}
            </Typography>
          </Box>
        </NavLink>
        <Box display="flex" alignItems="center" className="menu">
          <Box className="menu">
            <ToggleButtonGroup
              className="menu"
              value={i18n.language}
              exclusive
              size="small"
              onChange={toggleLanguage}
            >
              <ToggleButton value="fr">
                <Typography
                  fontSize="medium"
                  sx={{ width: "20px", height: "20px", color: "#ffffff", display: "block" }}
                >
                  FR
                </Typography>
              </ToggleButton>
              <ToggleButton value="en">
                <Typography
                  fontSize="medium"
                  sx={{ width: "20px", height: "20px", color: "#ffffff" }}
                >
                  EN
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>

      </Box>
      <Box className="intervalleH"></Box>
    </AppBar>
  );
};

export default Header;
