import { Box, Typography } from "@mui/material";
import "./footer.css";

const Footer = () => {
  return (
    <Box className="footBar">
      <Box className="intervalleH3"></Box>
      <Box className="footDiv">
        <Typography variant="body1">
          &copy; 2024 CGH
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
