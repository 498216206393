import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import BookCard from "../../models/bookCard";
import "./cardItem.css";

interface Props {
  bookCard: BookCard;
}

const CardItem = ({ bookCard }: Props) => {

  //  console.log("CardItem bookCard", bookCard);

  return (
    <>
      <Box className="item">
        <NavLink vertical-align="baseline" key={bookCard.id} to={"/CardView/" + bookCard.id}>
          <Typography align="left" height="4em" >
            {bookCard.fullname}
          </Typography>
        </NavLink>
      </Box>
    </>
  );
};

export default CardItem;
