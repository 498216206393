import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import i18n from "./i18n";

import { getDefaultLocale, setDefaultLocale } from "react-datepicker";

import "./App.css";

import Footer from "./components/footer/footer";
import Header from "./components/header/header";

import CardList from "./pages/cardList/cardList";
import MenuLetters from "./components/menuLetters/menuLetters";

import CardEdit from "./pages/cardEdit/cardEdit";
import CardView from "./pages/cardView/cardView";

import Error404 from "./pages/error404/error404";

import BookCard from "./models/bookCard";
import { bookCardData } from "./data/mockData";


function App() {

  const navigate = useNavigate();

  // variable d'initialisation pour lire le fichier mockData et le mettre dans cardOrigin (voir useEffect)

  const [initData, setInitData] = useState<number>(0);

  // tableau contenant le fichier json mockData.
  // C'est sur le fichier cardOrigin qu'on va travailler sans sauvegarde pour l'instant

  const [cardOrigin, setCardOrigin] = useState<BookCard[]>([]);

  // tableau contenant la fiche sur laquelle on travaille

  const [bookCard, setBookCard] = useState<BookCard | undefined>();

  const [sortField, setSortField] = useState<string>("fullname");
  const [sortFieldNext, setSortFieldNext] = useState<string>("firstname");

  const [sortDirection, setSortDirection] = useState<number>(1);

  const [datePickerLanguage, setDatePickerLanguage] = useState<string>("fr");

  const [action, setAction] = useState<string>("A");

  //  console.log("i18n.language", i18n.language);

  setDefaultLocale(i18n.language);

  //  console.log("APP getDefaultLocale", getDefaultLocale());

  const handleCardChange = (action: string, card: BookCard) => {
    let result: BookCard[];

    // console.log("APP 31 handleCardChange", card, action);

    switch (action) {
      case "A":
        result = cardOrigin;
        navigate("/");
        break;
      case "C":
        result = cardOrigin;
        result.push(card);
        navigate("/CardView/" + card.id);
        break;
      case "M":
        result = cardOrigin.filter(
          (delCard: BookCard) => card.id !== delCard.id
        );
        result.push(card);
        navigate("/CardView/" + card.id);
        break;
      case "D":
        result = cardOrigin.filter(
          (delCard: BookCard) => card.id !== delCard.id
        );
        navigate("/");
        break;
      default:
        result = cardOrigin;
        break;
    }

    setCardOrigin(result);
  };

  const handleCardSelect = (card: BookCard) => {
    setBookCard(card);
  }

  const sortFieldUpdate = () => {
    if (sortField === "fullname") {
      setSortField("firstname");
      setSortFieldNext("id");
    } else if (sortField === "firstname") {
      setSortField("id");
      setSortFieldNext("fullname");
    } else if (sortField === "id") {
      setSortField("fullname");
      setSortFieldNext("firstname");
    }
  };


  useEffect(() => {
    if (initData === 0) {
      setCardOrigin(bookCardData);
      setInitData(1);
    } else {
      setCardOrigin(cardOrigin);
    }
  }, [cardOrigin, initData]);


  //    window.location.replace("/");

  return (
    <div className="App">
      <div className="aGauche">
        <Header setDatePickerLanguage={setDatePickerLanguage} />

        <main>

          <Box className="mainBox">
            <Routes>
              <Route
                path="/"
                element={
                  <CardList
                    sortDirection={sortDirection}
                    cardOrigin={cardOrigin}
                    setBookCard={setBookCard}
                    action={action}
                    setAction={setAction}
                  />
                }
              />
              <Route
                path="/CardList/"
                element={
                  <CardList
                    sortDirection={sortDirection}
                    cardOrigin={cardOrigin}
                    setBookCard={setBookCard}
                    action={action}
                    setAction={setAction}
                  />
                }
              />
              <Route
                path="/CardEdit/"
                element={
                  <CardList
                    sortDirection={sortDirection}
                    cardOrigin={cardOrigin}
                    setBookCard={setBookCard}
                    action={action}
                    setAction={setAction}
                  />
                }
              />
              <Route
                path="/CardView/"
                element={
                  <CardList
                    sortDirection={sortDirection}
                    cardOrigin={cardOrigin}
                    setBookCard={setBookCard}
                    action={action}
                    setAction={setAction}
                  />
                }
              />
              <Route
                path="/CardList/:filter/:sortField"
                element={
                  <CardList
                    sortDirection={sortDirection}
                    cardOrigin={cardOrigin}
                    setBookCard={setBookCard}
                    action={action}
                    setAction={setAction}
                  />
                }
              />
              <Route
                path="/CardView/:filter"
                element={
                  <CardView
                    cardOrigin={cardOrigin}
                    bookCard={bookCard}
                    //  setBookCard={setBookCard}
                    action={action}
                    setAction={setAction}

                    handleCardChange={handleCardChange}
                    handleCardSelect={handleCardSelect}
                  />
                }
              />
              <Route
                path="/CardEdit/:filter"
                element={
                  <CardEdit
                    cardOrigin={cardOrigin}
                    bookCard={bookCard}
                    setBookCard={setBookCard}
                    handleCardChange={handleCardChange}
                    datePickerLanguage={datePickerLanguage}
                    action={action}
                    setAction={setAction}
                  />
                }
              />
              <Route path="/Error404/" element={<Error404 />} />
              <Route path="*" element={<Error404 />} />
            </Routes>

            <div className="aDroite2">
              <div className="intervalleV2"></div>
              <MenuLetters
                sortDirection={sortDirection}
                sortField={sortField}
                sortFieldUpdate={sortFieldUpdate}
                sortFieldNext={sortFieldNext}
                sortDirectionUpdate={setSortDirection}
              />
            </div>
          </Box>
        </main>

        <Footer />
      </div>

      <div className="aDroite">
        <div className="intervalleV"></div>
      </div>
    </div>
  );
}

export default App;
