import { useTranslation } from "react-i18next";

import { Box, Button, Typography } from "@mui/material";

import "./cardValid.css";

import BookCard from "../../models/bookCard";

interface Props {
    card: BookCard | undefined;
    action: string;
    setAction: Function;
    handleCloseValid: Function;
    handleCardChange: Function;
}

const CardValid = ({ action, setAction, card, handleCloseValid, handleCardChange }: Props) => {

    // console.log("CardValid 19 action", action);

    const { t } = useTranslation();

    setAction(action);

    const handleAction = () => {
        // Appeler handleCardChange avec les arguments appropriés
        // console.log("cardValid 22", action, card);
        handleCardChange(action, card);
        // Fermer la fenêtre pop-up ou effectuer d'autres actions nécessaires
        // (Ici, nous ne fermons qu'en définissant isPopupOpen à false)
        handleCloseValid();
    };

    let message: any = "";
    let button1: any = "";
    let button2: any = "";

    switch (action) {

        case "A":
            message = t("valid.cancel");
            button1 = t("common.stayrecord");
            button2 = t("common.cancelCardModif");
            break;
        case "D":
            message = t("valid.delete");
            button1 = t("common.cancel");
            button2 = t("common.deleteCard");
            break;
        case "M":
            message = t("valid.modify");
            button1 = t("common.cancel");
            button2 = t("common.recordCard");
            break;
        case "C":
            message = t("valid.create");
            button1 = t("common.cancel");
            button2 = t("common.recordCard");
            break;
        default:
    }

    return (
        <>
            <Box className="validationBox">
                <Box className="validationBox">
                    <Typography className="message">
                        {message}
                    </Typography>
                    <Button className="validButtonBox" variant="contained" sx={{ backgroundColor: "#3465a4", color: "#ffffff" }} onClick={() => handleCloseValid()}>
                        <Typography>{button1}</Typography>
                    </Button>
                    <Button className="validButtonBox" variant="contained" sx={{ backgroundColor: "#3465a4", color: "#ffffff" }} onClick={() => handleAction()}>
                        <Typography>{button2}</Typography>
                    </Button>
                </Box>

            </Box >

        </>
    );
};

export default CardValid;
